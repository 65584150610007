body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.avatar-uploader>.ant-upload {
  width: 80px;
  height: 80px;
}

.ant-drawer-wrapper-body {
  height: 100%;
  overflow: auto;
}

.object-list-body {
  width: 100%
}

.object-list-header {
  display: flex;
}

.object-list-header div {
  flex: 1 0;
}

.object-list-row {
  display: flex;
}

.object-list-row input {
  flex: 1 0;
}

.object-list-row .ant-input-number {
  flex: 1 0;
}

.ant-modal-header {
  background: #001529;
  color: #fff
}

.ant-modal-title {
  color: #fff
}

.ant-modal-close {
  color: hsla(0, 0%, 100%, .65);
}

.ant-modal-close:hover {
  color: #fff;
}

/* .ant-descriptions-item {
  display: flex;
}

.ant-descriptions-item-content {
  width: 100%;
} */

.content-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.content-label .value {
  white-space: pre-wrap;
  text-align: right;
  max-width: 200px
}

.report {
  border-radius: 4px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.report-label {
  background-color: #fafafa;
}

.report .ant-col {
  padding: 16px 24px !important;
}

.ant-collapse-content>.ant-collapse-content-box {
  padding: 0 !important;
}

.ant-layout-sider-children {
  overflow: auto;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

.ant-card-cover img {
  display: block;
  width: 100%;
}

.ant-row {
  max-width: 90vw;
}

.payment {
}

.payment .ant-card-body{
  display: flex;
}
.paymentImg {
  flex: 1 0 50%;
  max-width: 50%;
}
.paymentImg>div {
  width: 100%;
  padding: 0 8px;
}
.paymentImg>div>img {
  max-width: 100%;
}